<template>
  <v-form ref="form" lazy-validation>
    <v-dialog
      v-model="showForm"
      max-width="1000px"
      persistent
      transition="dialog-top-transition"
      @keydown.esc="close"
    >
      <v-card>
        <v-card-title
          >Client: {{ client }}
          <v-spacer></v-spacer>
          <v-btn text @click="close">
            <v-icon left> mdi-close-thick </v-icon>Fermer</v-btn
          ></v-card-title
        >
        <v-card-subtitle
          >Produit: {{ product.code + " / " + product.label }}</v-card-subtitle
        >
        <v-divider class="mb-6"></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12" lg="10" md="10" sm="10">
              <v-text-field
                autocomplete="off"
                type="text"
                dense
                v-model="form.clientEmail"
                label="À"
                :rules="[
                  (v) => !!v || 'Email obligatoire !!', // Field is required
                  (v) => {
                    const emails = v.split(';').map((email) => email.trim()); // Split and trim emails
                    return (
                      emails.every((email) => /.+@.+\..+/.test(email)) ||
                      'Un ou plusieurs emails ne sont pas valides'
                    );
                  },
                ]"
              ></v-text-field>
              <v-text-field
                autocomplete="off"
                type="text"
                dense
                v-model="form.cc"
                label="Cc"
                :rules="[
                  (v) => {
                    if (!v) return true; // Allow empty field
                    const emails = v.split(';').map((email) => email.trim()); // Split and trim emails
                    return (
                      emails.every((email) => /.+@.+\..+/.test(email)) ||
                      'Un ou plusieurs emails ne sont pas valides'
                    );
                  },
                ]"
              ></v-text-field>
              <v-text-field
                autocomplete="off"
                type="text"
                dense
                v-model="form.subject"
                label="Objet"
                :rules="[(v) => !!v || 'Objet obligatoire !!']"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              lg="2"
              md="2"
              sm="2"
              class="d-flex align-center justify-center"
            >
              <v-btn
                outlined
                class="mt-3"
                color="purple"
                @click="sendEmail"
                :disabled="!bag || !form.clientEmail"
                :loading="progress"
                style="
                  width: 100px;
                  height: 100px;
                  border-radius: 3px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
                elevation="1"
              >
                <v-icon large>mdi-email-fast</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-chip
            class="ma-2"
            @click="previewPdf"
            :disabled="!bag"
            :loading="progress"
            small
            dark
            color="purple"
          >
            <v-icon left small> mdi-paperclip </v-icon>
            {{ bag ? JSON.parse(bag).filename : "Aperçu BAG" }}
          </v-chip>
          <v-divider class="mb-6"></v-divider>
          <v-textarea
            v-model="form.message"
            :rules="[(v) => !!v || 'Message obligatoire !!']"
            rows="15"
            dense
            filled
            outlined
          ></v-textarea>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <FileUploadProgress :isProcessing="isProcessing" :uploading="false" />
  </v-form>
</template>

<script>
import axios from "axios";
import { api, myName, myadress } from "print/data.js";
import { generatePdf } from "print/bag_generator.js";

export default {
  props: {
    product: Object,
    productcode: String,
    bag: String,
    client: String,
    adresse: String,
    showForm: Boolean,
  },
  components: {
    FileUploadProgress: () => import("../components/FileUploadProgress.vue"),
  },
  data() {
    return {
      progress: false,
      form: {
        title: "Validation BAG: " + this.product.label,
        clientEmail: "",
        cc: "",
        clientName: "",
        productcode: "",
        userName: this.$store.state.me.name || "[Votre Prénom et Nom]",
        userPosition: this.$store.state.me.fonction || "[Votre Fonction]",
        companyName: this.mycompany || "[Nom de l'entreprise]",
        companyContact: this.myadress || "[Coordonnées de l'entreprise]",
        subject: "Validation BAG: " + this.product.label,
        message: `Bien le Bonjour à toute l'equipe de [Nom du Client] ,

  Nous Vous soumettons ci joint le bon à graver concernant la maquette de votre produit: 
  [Nom Produit]  -  (réferencé chez nous sous le code. [Code Produit]) 
  et vous prions de bien vouloir verifier mituculeusement toutes les informations (texte, graphismes, dimensions, etc.)
  afin de s'assurer qu'elles sont  conformes à vos attentes. Votre validation est essentielle pour que nous puissions 
  lancer l'impression dans les delais impartis.

  Si tout est conforme, merci de nous confirmer votre accord par retour de mail en jpignant le BAG signé avec les informations 
  du sens et du diamètre mandrin rensignés. et de nous indiquer votre code unique pour ce produit 
  (à défaut prière de mentionner notre code [Code Produit] dans vos futures commandes)
  
  Si vous souhaitez apporter des modifications, veuillez nous les indiquer dès que possible afin que nous puissions les traiter.
  
  Nous restons à votre entière disposition pour toute question ou clarification supplémentaire.
  
  Merci de votre confiance et de votre collaboration.
  
  Bien cordialement,
  
  [Votre Prénom et Nom]  
  [Votre Fonction]  
  [Nom de l'entreprise]  
  [Coordonnées de l'entreprise]`,
      },
      snackbar: false,
      snackbar_timeout: 5000,
      snackbar_text: "",
      snackbar_color: "primary",
      isProcessing: false,
    };
  },
  mounted() {
    this.form.clientEmail = this.adresse;
    this.form.companyName = this.mycompany;
    this.form.companyContact = this.myadress;
    this.form.productcode = this.productcode;
    // Customize the message template
    this.form.message = this.form.message
      .replace("[Nom du Client]", this.client)
      .replace("[Nom Produit]", this.product.label)
      .replace("[Code Produit]", this.form.productcode)
      .replace("[Code Produit]", this.form.productcode)
      .replace("[Votre Prénom et Nom]", this.form.userName)
      .replace("[Votre Fonction]", this.form.userPosition)
      .replace("[Nom de l'entreprise]", this.form.companyName)
      .replace("[Coordonnées de l'entreprise]", this.form.companyContact);
  },
  computed: {
    mycompany() {
      return myName;
    },
    myadress() {
      return myadress;
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
    previewPdf() {
      const fileUrl = JSON.parse(this.bag).url;
      this.isProcessing = true;
      generatePdf(api, fileUrl, this.product, (doc) => {
        this.isProcessing = false;
        // Use arrow function to preserve `this`
        // Convert the PDF to a Blob
        doc.setProperties({ title: `${this.product.label}.pdf` });

        const pdfBlob = doc.output("blob");

        // Create a URL for the Blob
        const pdfUrl = URL.createObjectURL(pdfBlob);

        // Open the PDF in a new tab for preview
        window.open(pdfUrl, "_blank");

        // Optionally, you can also provide the user with an option to send the email after preview
        // Example: Show a modal or a confirmation dialog
        // You could store the PDF URL in a component property and use it in a modal or a dialog
        this.pdfPreviewUrl = pdfUrl;
      });
    },

    sendEmail() {
      const fileUrl = JSON.parse(this.bag).url;
      this.isProcessing = true;
      generatePdf(api, fileUrl, this.product, (doc) => {
        // Use arrow function to preserve `this`
        // Convert the PDF to a Blob
        const pdfBlob = doc.output("blob");
        this.isProcessing = false;
        // Example function to handle sending email
        const formData = new FormData();
        formData.append(
          "pdf",
          pdfBlob,
          "document_" + this.form.productcode + ".pdf"
        );
        formData.append("product_id", this.product.id);
        formData.append("clientEmail", this.form.clientEmail);
        formData.append("cc", this.form.cc);
        formData.append("message", this.form.message);
        formData.append("title", this.form.title);
        this.progress = true;

        axios
          .post(api + "send-email", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            this.$emit("success", response.data.email_sent_at);
            this.progress = false;
            this.snackbar_text = "Email envoyé avec succès";
            this.snackbar_color = "success";
            this.snackbar = true;
            this.close();
          })
          .catch((error) => {
            if (error.response && error.response.status === 422) {
              this.snackbar_text = "Adresse Email Introuvable pour ce client";
              this.snackbar_color = "error";
            } else {
              this.snackbar_text = "Erreur lors de l'envoi de l'email";
              this.snackbar_color = "error";
            }
            this.snackbar = true;
            this.progress = false;
          });
      });
    },
  },
};
</script>
